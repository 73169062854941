class WappoTable extends HTMLElement {
    rowHeight = 25;
    extraRows = 6;
    visibleRowCount = -1;

    _cellsWidths = [];
    _cellLefts = [];
    _columnKeys = [];
    _pathCache = {};
    _parsedItems = [];

    constructor() {
        super();
    }

    setItems(items) {
        this._items = items;
        this._body.style.height = (this._items.length * this.rowHeight) + 'px';
        this._parseItems();
        this.setVisibleData();
    }

    _parseItems() {
        this._parsedItems = this._items.map(item => {
            return this._columnKeys.map(path => this.resolve(item, path));
        });
    }

    /**
     * Resolver-funktionen flyttas utanför setVisibleData för att undvika att den återskapas vid varje anrop.
     * @param {Object} item
     * @param {string} path
     * @returns
     */
    resolve(item, path) {
        if (path in this._pathCache) {
            return this._pathCache[path](item);
        }

        if (this._pathCache[path]) {
            return this._pathCache[path](item);
        }

        let resolver;
        if (path.includes('.')) {
            // Skapa en funktion som navigerar genom objektet baserat på path
            const props = path.split('.').map(p => `['${p}']`).join('');
            resolver = new Function('item', `try { return item${props}; } catch (e) { return null; }`);
        } else {
            // Direkt åtkomst om path inte innehåller punkt
            resolver = new Function('item', `return item['${path}'];`);
        }

        this._pathCache[path] = resolver;
        return resolver(item);
    };


    setVisibleData() {
        const start = Math.floor(this._viewport.scrollTop / this.rowHeight);
        const renderStart = start - (this.extraRows / 2);
        const itemsToRender = this.visibleRowCount + this.extraRows;

        const columnKeys = this._columnKeys;
        const columnKeysLength = columnKeys.length;

        this._applyRowPosition(this._rowsViewport, 0);
        for (let i = 0; i < itemsToRender; i++) {
            const itemIndex = i + renderStart;
            const item = this._parsedItems[itemIndex];
            for (let x = 0; x < columnKeysLength; x++) {
                const cell = this._rowCells[i][x];
                cell.firstChild.nodeValue = item?.[x] ?? '';
            }
        }
    }

    _setColumnWith(index, width) {
        const columns = this.querySelectorAll('wa-table-column');
        let availableWidth = this.offsetWidth;
        let left = 0;

        for (let i = 0; i < columns.length; i++) {
            let colWidth = Math.min(availableWidth, (i === index ? width : columns[i].offsetWidth));
            if (i === columns.length - 1) {
                colWidth = availableWidth;
            }

            this._cellsWidths[i] = colWidth;
            this._cellLefts[i] = left;

            left += colWidth;
            availableWidth -= colWidth;

            this._positionColumn(columns[i], i);
            this._positionCells(i);
        }
    }

    _positionColumn(column, index) {
        column.style.width = this._cellsWidths[index] + 'px';
        column.style.left = this._cellLefts[index] + 'px';
    }

    _positionCells(colIndex) {
        for (let i = 0; i < this._cells[colIndex].length; i++) {
            this._applyCellPosition(this._cells[colIndex][i], colIndex);
        }
    }

    _parseColumns() {
        const columns = this.querySelectorAll('wa-table-column');
        const availableWidth = this.offsetWidth;
        const columnWidth = Math.floor(availableWidth / columns.length);
        let left = 0;

        for (let i = 0; i < columns.length; i++) {
            this._cellsWidths[i] = columnWidth;
            this._cellLefts[i] = left;
            left += columnWidth;
        }

        for (let i = 0; i < columns.length; i++) {
            columns[i].style.width = this._cellsWidths[i] + 'px';
            columns[i].style.left = this._cellLefts[i] + 'px';

            columns[i].addEventListener('resize', (e) => {
                const {width} = e.detail;
                this._setColumnWith(i, width);
            });

            this._columnKeys.push(columns[i].getAttribute('key'));
        }
    }

    _applyCellPosition(cell, index) {
        const width = this._cellsWidths[index];
        const left = this._cellLefts[index];

        cell.style.width = width + 'px';
        cell.style.left = left + 'px';
    }

    _applyRowPosition(row, index) {
        const before = this.extraRows / 2;
        const visibleIndex = index - before;
        const hidden = Math.floor(this._viewport.scrollTop / this.rowHeight);
        const hiddenY = hidden * this.rowHeight;
        const yPos = (this._viewport.scrollTop + (this.rowHeight * visibleIndex)) - (this._viewport.scrollTop - hiddenY);

        row.style.transform = 'translate3d(0, ' + yPos + 'px, 0)';
    }

    _buildRows() {
        this.visibleRowCount = Math.ceil(this.getBoundingClientRect().height / this.rowHeight);
        const visibleRows = this.visibleRowCount + this.extraRows;
        const columnCount = this.querySelectorAll('wa-table-column').length;
        this._rows = [];
        this._rowCells = [];
        this._cells = [];

        for (let i = 0; i < columnCount; i++) {
            this._cells.push([]);
        }

        for (let i = 0; i < visibleRows; i++) {
            const row = document.createElement('wa-table-row');
            let rowCells = [];
            for (let x = 0; x < columnCount; x++) {
                const cell = document.createElement('wa-table-cell');
                cell.append(document.createTextNode(''));
                cell.setAttribute('row', i);
                cell.setAttribute('col', x);
                this._applyCellPosition(cell, x);
                row.append(cell);

                rowCells.push(cell);
                this._cells[x].push(cell);
            }

            this._applyRowPosition(row, i);
            this._rowsViewport.appendChild(row);
            this._rowCells.push(rowCells);
            this._rows.push(
                row
            );
        }
    }

    viewport_scroll(e) {
        this.setVisibleData();
    }

    _buildDom() {
        this._header = document.createElement('wa-table-header');
        this._viewport = document.createElement('wa-table-viewport');
        this._body = document.createElement('wa-table-body');
        this._rowsViewport = document.createElement('div');
        this._rowsViewport.style.position = 'absolute';

        this._body.append(this._rowsViewport);
        this.append(this._header, this._viewport);
        this._viewport.append(this._body);
        this._viewport.addEventListener('scroll', (e) => this.viewport_scroll(e));

        this.querySelectorAll('wa-table-column').forEach(col => this._header.appendChild(col));
    }

    _repositionViewport() {
        this._viewport.style.height = (this.getBoundingClientRect().height - this._header.getBoundingClientRect().height) + 'px';
    }

    connectedCallback() {
        this._buildDom();
        this._parseColumns();
        this._buildRows();
        this._repositionViewport();
    }

    disconnectedCallback() {
        console.log("Custom element removed from page.");
    }

    adoptedCallback() {
        console.log("Custom element moved to new page.");
    }

    attributeChangedCallback(name, oldValue, newValue) {
        console.log(`Attribute ${name} has changed.`);
    }
}

class WappoTableColumn extends HTMLElement {
    static observedAttributes = ["key", "resizable"];

    isResizing = false;

    _clientX = 0;

    constructor() {
        super();
    }

    _buildDom() {
        this._resizeHandle = document.createElement('wa-table-column-resize-handle');
        this.appendChild(this._resizeHandle);
    }

    _bindEvents() {
        this._resizeHandle.addEventListener('mousedown', (e) => {
            this.isResizing = true;
            this._clientX = e.clientX;
            this._width = this.offsetWidth;
        });

        document.body.addEventListener('mousemove', (e) => {
            if (this.isResizing) {
                const width = this._width + (e.clientX - this._clientX);
                this.dispatchEvent(new CustomEvent('resize', {
                    detail: {width}
                }));
            }
        });

        document.body.addEventListener('mouseup', () => {
            this.isResizing = false;
        });
    }

    connectedCallback() {
        this._buildDom();
        this._bindEvents();
    }

    disconnectedCallback() {
        console.log("Custom element removed from page.");
    }

    adoptedCallback() {
        console.log("Custom element moved to new page.");
    }

    attributeChangedCallback(name, oldValue, newValue) {
        console.log(`Attribute ${name} has changed.`);
    }
}

class WappoTableColumnResizeHandle extends HTMLElement {

}

class WappoTableHeader extends HTMLElement {

}

class WappoTableViewport extends HTMLElement {
}

class WappoTableBody extends HTMLElement {
}

class WappoTableRow extends HTMLElement {
}

class WappoTableCell extends HTMLElement {
}

customElements.define("wa-table", WappoTable);
customElements.define("wa-table-column", WappoTableColumn);
customElements.define('wa-table-header', WappoTableHeader);
customElements.define('wa-table-viewport', WappoTableViewport);
customElements.define('wa-table-body', WappoTableBody);
customElements.define('wa-table-row', WappoTableRow);
customElements.define('wa-table-cell', WappoTableCell);
customElements.define('wa-table-column-resize-handle', WappoTableColumnResizeHandle);